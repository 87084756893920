import { clients } from "data";
import React from "react";
import styled from "styled-components";

const CardClient = () => {
  return (
    <StyledCard style={{ background: "#FFFFFF" }}>
      {clients.map((client) => (
        <StyledSpan>{client}</StyledSpan>
      ))}
    </StyledCard>
  );
};

const StyledCard = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  box-shadow: transparent 0px 0px 0px 2px inset;
`;

const StyledSpan = styled.span`
  position: absolute;
  right: 14px;
  top: 14px;

  border-radius: 18px;
  padding: 10px 16px;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  opacity: 0.5;
`;

export default CardClient;
