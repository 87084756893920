import React from "react";
import styled from "styled-components";
import Link from "./Link";

const CardSocial = ({ color, icon, to }: any) => {
  const SocialIcon = icon;

  return (
    <StyledCard style={{ background: color }}>
      <SocialIcon />

      <Link to={to} />
    </StyledCard>
  );
};

const StyledCard = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  box-shadow: transparent 0px 0px 0px 2px inset;
  position: relative;
`;

export default CardSocial;
