import React from "react";
import styled from "styled-components";
import Link from "./Link";

const CardWorkThree = ({ data }: { data: any }) => (
  <StyledCard>
    <img src={data.imageOne} width={data.width} />

    <StyledCardText>
      <h3>{data.title}</h3>
      <StyledSpan>{data.description}</StyledSpan>
    </StyledCardText>

    <Link to={data.to} />
  </StyledCard>
);

const StyledCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 32px;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  box-shadow: transparent 0px 0px 0px 2px inset;
  position: relative;
`;

const StyledCardText = styled.div`
  padding: 16px 32px;
`;

const StyledSpan = styled.span`
  color: #424242;
  font-size: 14px;
  letter-spacing: -0.7px;
  font-weight: 300;
`;

export default CardWorkThree;
