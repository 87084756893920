import { AboutT, ClientT, WorkT, SocialT } from "types";
import { LOGO_DIGITAL_CSE, LOGO_LE_REACTEUR, LOGO_MALT } from "./assets/logos";

import {
  DiscordIcon,
  GithubIcon,
  LinkedinIcon,
  TwitchIcon,
  TwitterIcon
} from "./assets/socials";

export const about: AboutT[] = [
  {
    line1: "Founder & CTO",
    line2: "Digital CSE",
    color: "#6783fa",
    image: { src: LOGO_DIGITAL_CSE, width: 92 },
    to: "https://digitalcse.fr"
  },
  {
    line1: "Freelance",
    line2: "developer",
    color: "#fc6969",
    image: { src: LOGO_MALT, width: 76 },
    to: "https://www.malt.fr/profile/stevenpersia"
  },
  {
    line1: "Teacher",
    line2: "Le Reacteur",
    color: "#8677de",
    image: { src: LOGO_LE_REACTEUR, width: 76 },
    to: "https://lereacteur.io"
  }
];

export const clients: ClientT[] = [
  { name: "Super U", color: "#fcddb7" },
  { name: "Intermarché", color: "#fcddb7" },
  { name: "Bricorama", color: "#fcddb7" },
  { name: "MadeCELL", color: "#eaedf2" },
  { name: "Renault Trucks", color: "#fcddb7" },
  { name: "APHP", color: "#d6edff" },
  { name: "Croc Frais", color: "#fcddb7" },
  { name: "Brico Dépôt", color: "#fcddb7" },
  { name: "Holy Owly", color: "#fcddb7" },
  { name: "Boobox", color: "#fce4e8" },
  { name: "Le Reacteur", color: "#e8e5f9" },
  { name: "Lisn Kids", color: "#e8eaeb" }
];

export const works: WorkT[] = [
  {
    title: "captain-hook",
    description: "Custom React hooks for your project ",
    to: "https://github.com/stevenpersia/captain-hook"
  },
  {
    title: "tinyboo",
    description: "Utilities library for type checking",
    to: "https://github.com/stevenpersia/tinyboo"
  },
  {
    title: "start-expo",
    description: "Minimal starter kit for Expo.io project",
    to: "https://github.com/stevenpersia/start-expo"
  },
  {
    title: "tinder-react-native",
    description: "Tinder clone in React Native",
    to: "https://github.com/stevenpersia/tinder-react-native"
  },
  {
    title: "a119",
    description: "Lightweight React Framework UI",
    to: "https://github.com/stevenpersia/a119"
  },
  {
    title: "leboncoin-react",
    description: "Clone of Leboncoin made with React",
    to: "https://github.com/stevenpersia/leboncoin-react"
  },
  {
    title: "Lisn Kids",
    description: "...",
    to: "https://lisnkids.com"
  },
  {
    title: "Sunbe",
    description: "...",
    to: "https://sunbe.com"
  },
  {
    title: "Sunbe",
    description: "...",
    to: "https://sunbe.com"
  }
];

export const socials: SocialT[] = [
  {
    id: "github",
    icon: GithubIcon,
    to: "https://github.com/stevenpersia",
    color: "#424242"
  },
  {
    id: "linkedin",
    icon: LinkedinIcon,
    to: "https://linkedin.com/in/stevenpersia",
    color: "#0274b3"
  },
  {
    id: "twitch",
    icon: TwitchIcon,
    to: "https://twitch.tv/stevenpersia",
    color: "#9147ff"
  },
  {
    id: "twitter",
    icon: TwitterIcon,
    to: "https://twitter.com/stevenpersia_js",
    color: "#1da1f2"
  }
];

export const skills = [
  "JavaScript",
  "TypeScript",
  "Eslint",
  "Prettier",
  "Git",
  "Node.js",
  "Express.js",
  "MongoDB",
  "MySQL",
  "React",
  "React Native",
  "Expo",
  "Gatsby",
  "Svelte",
  "Electron",
  "NPM",
  "Styled Components",
  "Xcode",
  "Ubuntu",
  "NGINX",
  "Let's Encrypt",
  "Netlify",
  "Digital Ocean",
  "Socket.io",
  "Amazon AWS",
  "Heroku",
  "Postman",
  "Firebase",
  "Directus",
  "Jira",
  "Sentry",
  "Plesk",
  "AuthO",
  "Ant Design",
  "Material UI"
];
