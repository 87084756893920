import React from "react";
import styled from "styled-components";
import Link from "./Link";

const CardWorkTwo = ({ data }: { data: any }) => (
  <StyledCard>
    <StyledCardText>
      <h3>{data.title}</h3>
      <StyledSpan>{data.description}</StyledSpan>
    </StyledCardText>

    <StyledCardImages>
      <img src={data.imageOne} />
      <img src={data.imageTwo} />
    </StyledCardImages>

    <Link to={data.to} />
  </StyledCard>
);

const StyledCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  border-radius: 32px;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  box-shadow: transparent 0px 0px 0px 2px inset;
  overflow: hidden;
  position: relative;
`;

const StyledCardText = styled.div`
  width: 195px;
`;

const StyledSpan = styled.span`
  color: #424242;
  font-size: 14px;
  letter-spacing: -0.7px;
  font-weight: 300;
`;

const StyledCardImages = styled.div`
  position: absolute;
  left: -30px;

  & img {
    width: 180px;
  }

  & img:first-child {
    transform: rotate(0.03turn);
  }

  & img:last-child {
    margin-left: 10px;
    transform: rotate(0.03turn);
  }
`;

export default CardWorkTwo;
