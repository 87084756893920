import React from "react";
import styled from "styled-components";

const Link = ({ to }: any) => {
  return (
    <StyledButton href={to} target="_blank" rel="noreferrer">
      <svg
        id="Arrow.7"
        xmlns="http://www.w3.org/2000/svg"
        width="18.256"
        height="18.256"
        viewBox="0 0 18.256 18.256"
      >
        <g id="Group_7" data-name="Group 7" transform="translate(5.363 5.325)">
          <path
            id="Path_10"
            data-name="Path 10"
            d="M14.581,7.05,7.05,14.581"
            transform="translate(-7.05 -7.012)"
            fill="none"
            stroke="#0D1117"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          ></path>
          <path
            id="Path_11"
            data-name="Path 11"
            d="M10,7l5.287.037.038,5.287"
            transform="translate(-7.756 -7)"
            fill="none"
            stroke="#0D1117"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          ></path>
        </g>
        <path
          id="Path_12"
          data-name="Path 12"
          d="M0,0H18.256V18.256H0Z"
          fill="none"
        ></path>
      </svg>
    </StyledButton>
  );
};

const StyledButton = styled.a`
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  position: absolute;
  bottom: 14px;
  left: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.2s ease-out 0s;
  will-change: transform;

  &:hover {
    cursor: pointer;
    transition: box-shadow 0.2s ease-out 0s;
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 1px,
      rgba(255, 255, 255, 0.5) 0px 0px 0px 6px;
  }
`;

export default Link;
