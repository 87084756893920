import React, { useState } from "react";
import styled from "styled-components";
import { G01, G02, G03, G04, G05 } from "assets/gifs";

const CardGif = () => {
  const GIF = [G01, G02, G03, G04, G05];
  const randomNumber = Math.floor(Math.random() * GIF.length);
  const [gifNum, setGifNum] = useState(randomNumber);

  const backgroundStyle = {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${GIF[gifNum]})`,
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  };

  const changeGif = () => {
    if (gifNum < GIF.length - 1) setGifNum((prevState) => prevState + 1);
    else setGifNum(0);
  };

  return (
    <StyledCard onMouseOver={changeGif} style={backgroundStyle}>
      <div></div>
    </StyledCard>
  );
};

const StyledCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 32px;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  box-shadow: transparent 0px 0px 0px 2px inset;
`;

export default CardGif;
