import React from "react";
import styled from "styled-components";

const Card = ({ children }: { children: any }) => {
  return <StyledCard>{children}</StyledCard>;
};

const StyledCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 32px;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  box-shadow: transparent 0px 0px 0px 2px inset;
`;

export default Card;
