import React from "react";
import styled from "styled-components";
import {
  Card,
  CardSocial,
  CardWorkOne,
  CardWorkTwo,
  CardWorkThree,
  CardWorkFour,
  CardGif
} from "./components";
import { clients, skills } from "./data";

import ME from "./assets/images/me.png";
import TINDER_ONE from "./assets/images/tinder-1.png";
import TINDER_TWO from "./assets/images/tinder-2.png";
import LISNKIDS_ONE from "./assets/images/lisnkids-1.jpg";
import LISNKIDS_TWO from "./assets/images/lisnkids-2.jpg";
import TINYBOO from "./assets/images/tinyboo.png";
import A119 from "./assets/images/a119.png";
import CAPTAIN_HOOK from "./assets/images/captain-hook.png";
import SUNBE_ONE from "./assets/images/sunbe-1.png";
import SUNBE_TWO from "./assets/images/sunbe-2.png";

import "./reset.css";
import "./styles.css";
import {
  GithubIcon,
  LinkedinIcon,
  TwitchIcon,
  TwitterIcon
} from "assets/socials";

const App = () => {
  return (
    <div className="grid">
      <div className="about">
        <Card>
          <div style={{ margin: 32 }}>
            <div className="bm-pl">
              <div className="bm-pl__blob bm-pl__blob--r"></div>
              <div className="bm-pl__blob bm-pl__blob--g"></div>
              <div className="bm-pl__blob bm-pl__blob--b"></div>
            </div>
            <img src={ME} width={72} />
            <p>
              I'm <span className="name">Steven Persia</span> , a fullstack
              JavaScript developer and an open source contributor from France.
              I'm currently {" "}
              <a
                href="https://www.welcometothejungle.com"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                front-end dev @WTTJ
              </a>
                {" "}and{" "}
              <a
                href="https://digitalcse.fr"
                target="_blank"
                rel="noreferrer"
                className="link-3"
              >
                co-founder @Digital CSE
              </a>
              .
            </p>
          </div>
        </Card>
      </div>

      <div className="gif">
        <CardGif />
      </div>

      <div className="skills">
        <Card>
          <div style={{ margin: 32 }}>
            <h2>Skills</h2>
            <ul>
              {skills.map((skill) => (
                <Tag key={skill}>{skill}</Tag>
              ))}
            </ul>
          </div>
        </Card>
      </div>

      <div className="github">
        <CardSocial
          color="#424242"
          icon={GithubIcon}
          to="https://github.com/stevenpersia"
        />
      </div>

      <div className="linkedin">
        <CardSocial
          color="#0274b3"
          icon={LinkedinIcon}
          to="https://linkedin.com/in/stevenpersia"
        />
      </div>

      <div className="twitch">
        <CardSocial
          color="#9147ff"
          icon={TwitchIcon}
          to="https://twitch.tv/stevenpersia"
        />
      </div>

      <div className="twitter">
        <CardSocial
          color="#1da1f2"
          icon={TwitterIcon}
          to="https://twitter.com/stevenpersia_js"
        />
      </div>

      <div className="clients">
        <Card>
          <div style={{ margin: 32 }}>
            <h2>Clients</h2>
            <ul>
              {clients.map((client) => (
                <Tag key={client.name}>{client.name}</Tag>
              ))}
            </ul>
          </div>
        </Card>
      </div>

      <div className="tinder">
        <CardWorkOne
          data={{
            title: "Tinder React Native",
            description: "Tinder UI Clone in React Native",
            imageOne: TINDER_ONE,
            imageTwo: TINDER_TWO,
            to: "https://github.com/stevenpersia/tinder-react-native"
          }}
        />
      </div>

      <div className="lisnkids">
        <CardWorkTwo
          data={{
            title: "Lisn Kids",
            description: "Audio series for kids",
            imageOne: LISNKIDS_ONE,
            imageTwo: LISNKIDS_TWO,
            to: "https://lisnkids.com"
          }}
        />
      </div>

      <div className="tinyboo">
        <CardWorkThree
          data={{
            title: "Tinyboo",
            description:
              "Utilities library for type checking and other useful things. Already more than 40 functions (TypeScript supported).",
            imageOne: TINYBOO,
            to: "https://github.com/stevenpersia/tinyboo"
          }}
        />
      </div>

      <div className="a119">
        <CardWorkThree
          data={{
            title: "a119",
            description: "Lightweight React Framework UI",
            imageOne: A119,
            width: 150,
            to: "https://github.com/stevenpersia/a119"
          }}
        />
      </div>

      <div className="captainhook">
        <CardWorkThree
          data={{
            title: "Captain Hook",
            description: "Collection of custom React hooks for your project",
            imageOne: CAPTAIN_HOOK,
            width: 40,
            to: "https://github.com/stevenpersia/captain-hook"
          }}
        />
      </div>

      <div className="sunbe">
        <CardWorkFour
          data={{
            title: "Sunbe",
            description: "Social mobile app",
            imageOne: SUNBE_ONE,
            imageTwo: SUNBE_TWO,
            to: "https://sunbe.com"
          }}
        />
      </div>
    </div>
  );
};

const Tag = styled.li`
  background-color: #e3e1fa;
  border-radius: 3px;
  color: #3726de;
  display: inline-block;
  font-size: 13px;
  margin: 2px 2px;
  padding: 0 6px;
`;

export default App;
