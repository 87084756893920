import React from "react";
import styled from "styled-components";
import Link from "./Link";

const CardWorkOne = ({ data }: { data: any }) => (
  <StyledCard>
    <StyledCardText>
      <h3>{data.title}</h3>
      <StyledSpan>{data.description}</StyledSpan>
    </StyledCardText>

    <StyledCardImages>
      <img src={data.imageOne} />
      <img src={data.imageTwo} />
    </StyledCardImages>

    <Link to={data.to} />
  </StyledCard>
);

const StyledCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 32px;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  box-shadow: transparent 0px 0px 0px 2px inset;
  overflow: hidden;
  position: relative;
`;

const StyledCardText = styled.div`
  padding-left: 32px;
`;

const StyledSpan = styled.span`
  color: #424242;
  font-size: 14px;
  letter-spacing: -0.7px;
  font-weight: 300;
`;

const StyledCardImages = styled.div`
  & img {
    width: 210px;
    position: absolute;
    top: 0;
  }

  & img:first-child {
    right: -55px;
    transform: rotate(0.03turn);
  }

  & img:last-child {
    right: 50px;
    transform: rotate(-0.03turn);
  }
`;

export default CardWorkOne;
